* {
    box-sizing: border-box;
    font-family: Rawline, sans-serif;
}

html,
body{
    padding: 0;
    margin: 0;
    background-color: #eceaea;
}