.Status-On{
display: flex;
align-items: flex-start;
justify-content: center;
margin: 1rem;
background: linear-gradient(60deg, #66bb6a, #43a047);
border-radius: 5px;
padding: 0.6rem 0.2rem 0rem 0.2rem;

}
.Status-Off{
display: flex;
align-items: flex-start;
justify-content: center;
margin: 1rem;
background: linear-gradient(90deg, rgba(194,17,17,1) 0%, rgba(209,23,23,1) 50%, rgba(194,17,17,1) 100%);
border-radius: 5px;
padding: 0.6rem 0.5rem 0rem 0.5rem;

}

.Status-Text {
    margin-left: 0.5rem;
}
