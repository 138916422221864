.icon{
    max-width: 30px;
    max-height: 30px;
}


.itemsContainer{ 
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

}

.title{
    flex-basis: 100%;
    text-align: left;
    margin: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
}

.Card {
    width: 18rem;
    border-radius: 5px;
    box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.40);
    background: #2F4668;

    position: relative;
    display: flex;
    flex-direction: column;

    text-align: center;
    margin: 9px;
    color: #ffffff;
}

.web-btn {
    text-decoration: none;
    color: #ffffff;
}

@media (max-width: 800px){
    .title{
        font-size: 92%;
    }
}