.Card-Footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
}

.Last-Update {
    font-size: 12px;
    margin: 0.2rem;
}