.Container{ 
position: sticky;
top: 0;
left: 0;
right: 0;
z-index: 20;
}

.Header{
  width: 100%;
  height: 120px;
  padding: 0 1.3rem 0 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ececec;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 55%);
}

.CTIC_Logo{ 
  height: 80px;
  width: 180px;
}

.UFPA{
  display: flex;
  align-items: flex-end;
  margin: 0.3rem;
}

.UFPA_Logo {
  height: 100px;
  width: 80px;
}

.Text h1{
  display: flex;
  color: #2F4668;
  font-size: 32px;
  align-items: center;
}

.Text{
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
}

.Text h3{
  padding-top: 0; 
  padding-bottom: 0; 
  font-size: 20px;
  color: #495057;
}


/* Media Queries */

@media (max-width: 800px){
  .CTIC_Logo{
      display: none;
  }
  .Text h1{
    font-size: 92%;
  }
  .Text h3{
    font-size: 85%;
  }
  .UFPA_Logo{
    display: flex;
    height: 70px;
    width: 50px;
  }
}